html,
body {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

.index {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 1rem;
}

h1,
p {
  padding: 0;
  margin: 0;
}

p {
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

.content {
  display: grid;
  gap: 2rem;
}

form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 1rem;
}

label {
  display: grid;
  gap: 0.2rem;
  max-width: 20rem;
  text-align: left;
  font-size: 1rem;
}

input[type="text"] {
  padding: 0.4rem;
}

button {
  padding: 0.4rem;
}

ul {
  list-style: none;
  padding: 0;
  padding-top: 3rem;
  margin: 0;
  display: flex;
  gap: 2rem;
}

li {
  max-width: 20rem;
  text-align: left;
}

@media only screen and (max-width: 50rem) {
  ul {
    display: block;
  }

  li {
    padding-bottom: 1rem;
  }
}
